<template>
  <div class="all-home">
    <div class="home" v-if="!isMobilePlatform">
      <img class="home-text" src="../../assets/img/home/bg-text.png"/>
      <div class="home-organize">
        <div class="common-row">
          <div>
            <img src="../../assets/img/home/star.png"/>
            <span>主办单位：</span>
          </div>
          <span class="org-name">中国互联网协会、中兴通讯股份有限公司</span>
        </div>
        <div class="common-row">
          <div>
            <img src="../../assets/img/home/star.png"/>
            <span>承办单位：</span>
          </div>
          <span class="org-name">武汉丰迈信息技术有限公司</span>
        </div>
        <div class="common-row">
          <div>
            <img src="../../assets/img/home/star.png"/>
            <span>协办单位：</span>
          </div>
          <div>
            <p class="org-name">南京报业传媒集团、江苏电子信息职业学院、</p>
            <p class="org-name">金陵科技学院、郑州电力高等专科学校</p>
          </div>
        </div>
      </div>
      <template v-if="openRegistration">
        <!-- 开启报名 -->
        <img class="sign-up--btn" src="../../assets/img/home/sign-up-ing.png" @click="handleSignUp"/>
      </template>
      <template v-else>
        <!--报名截止 -->
        <img class="before-sign-up" src="../../assets/img/home/stop-sign-up.png"/>
      </template>

      <template v-if="openRegistration">
        <div v-if="isLogin" class="to-personal-center" @click="handleToPersonalCenter">前往个人中心 >></div>
      </template>
      <template v-else>
        <div class="to-personal-center another" @click="handleToPersonalCenter"> 查看报名信息>></div>
      </template>

      <div class="delay-notice" @click="handleDelay"> 2024年中兴捧月"星匠师"巧匠精英挑战赛全国决赛获奖名单</div>
    </div>

    <div class="mobile-home" v-else>
      <img class="mobile-home-text" src="../../assets/img/home/mobile-bg-text.png"/>
      <div class="home-organize">
        <div class="common-row">
          <div>
            <img src="../../assets/img/home/star.png"/>
            <span>主办单位：</span>
          </div>
          <span class="org-name">中国互联网协会、中兴通讯股份有限公司</span>
        </div>
        <div class="common-row">
          <div>
            <img src="../../assets/img/home/star.png"/>
            <span>承办单位：</span>
          </div>
          <span class="org-name">武汉丰迈信息技术有限公司</span>
        </div>
        <div class="common-row">
          <div>
            <img src="../../assets/img/home/star.png"/>
            <span>协办单位：</span>
          </div>
          <div>
            <p class="org-name">南京报业传媒集团、江苏电子信息职业学院、</p>
            <p class="org-name">金陵科技学院、郑州电力高等专科学校</p>
          </div>
        </div>
      </div>
      <!-- TODO iphone8 plus 机型不识别此判断，导致开启报名按钮一直出不来，需手动关闭报名入口 -->
      <template v-if="openRegistration">
        <!-- 开启报名 -->
        <img class="mobile-sign-up--btn" src="../../assets/img/home/mobile-sign-up-ing.png" @click="handleSignUp"/>
      </template>
      <template v-else>
        <!-- 截止报名 -->
        <img class="mobile-sign-up--btn" src="../../assets/img/home/mobile-stop-sign-up.png"/>
      </template>

      <div class="delay-notice">
        <a :href="linkUrl" download="星匠师巧匠精英挑战赛全国决赛延期通知.pdf">"2024年中兴捧月"星匠师"巧匠精英挑战赛全国决赛获奖名单</a>
      </div>

      <img class="mobile-official-event" src="../../assets/img/home/mobile-official-event.png"
           @click="handleToOfficial"/>
    </div>

    <delay-notice-dialog v-model="showDelayDialog"/>

  </div>
</template>

<script>
  import {mapGetters, mapState, mapMutations} from 'vuex'
  import DelayNoticeDialog from './components/delay-notice-dialog'

  export default {
    name: 'Home',
    data() {
      return {
        // 开始报名时间
        startTime: window._CONFIG["SIGN_UP_START_TIME"] || '2024-06-06 00:00:00',
        // 截止报名时间
        deadline: window._CONFIG["SIGN_UP_END_TIME"] || '2024-07-15 23:59:59',
        showDelayDialog: false
      }
    },
    computed: {
      ...mapGetters([
        'isLogin' // 是否已经登录账号
      ]),
      ...mapState(['isMobilePlatform', 'openRegistration']),
      guideUrl() {
        return window._CONFIG['TO_GUIDE_URL']
      },
      linkUrl() {
        let baseUrl = process.env.BASE_URL
        return `${baseUrl}file/notice.pdf`
      }
    },
    created() {
      this.setOpenRegistration();
    },
    components: {
      DelayNoticeDialog
    },
    methods: {
      ...mapMutations(['SET_OPENREGISTRATION']),
      // 是否开启报名判断
      setOpenRegistration() {
        const {startTime, deadline} = this
        let timeStart = new Date(startTime).getTime()
        let timeEnd = new Date(deadline).getTime()
        let currentTime = new Date().getTime()
        // console.log(startTime, deadline, timeStart, timeEnd, currentTime)
        let val = currentTime > timeStart && currentTime < timeEnd
        this.SET_OPENREGISTRATION(val)
      },
      handleDelay() {
        this.showDelayDialog = true;
      },
      handleSignUp() {
        this.$router.push({
          name: this.isMobilePlatform ? 'mobile-sign-up-management' : 'sign-up-management'
        })
      },
      handleToPersonalCenter() {
        this.$router.push({name: 'personal-center'})
      },
      handleToOfficial() {
        window.open(this.guideUrl, '_self')
      }
    }
  }
</script>

<style scoped lang="scss">
  .all-home {
    // pc样式
    .home {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      background: url("../../assets/img/home/bg.jpg") no-repeat center;
      background-size: cover;
      position: relative;

      .home-text {
        position: absolute;
        top: 28vh;
        left: 10%;
        width: 45%;
      }

      .home-organize {
        position: absolute;
        top: 50vh;
        left: 24%;
        .common-row {
          display: flex;
          margin-top: 8px;
          img {
            margin-right: 5px;
            margin-top: -5px;
            vertical-align: middle;
          }
          .org-name {
            margin: 0;
            color: #0E45A5;
            font-size: 18px;
            font-weight: 500;
          }
          span {
            font-size: 18px;
            font-weight: 500;
            &:first-child {
              color: #222222
            }
          }

        }
      }

      .before-sign-up {
        position: absolute;
        top: 69vh;
        left: 23%;
        width: 28%;
      }

      .sign-up--btn {
        position: absolute;
        width: 16%;
        top: 69vh;
        left: 25%;
        cursor: pointer;

        &:hover {
          filter: drop-shadow(0 0 2em #2F5CC8);
        }
      }

      .to-personal-center {
        position: absolute;
        top: 81vh;
        left: 25%;
        width: 16%;
        height: 48px;
        background: url("../../assets/img/home/to-personal-btn.png") no-repeat center;
        line-height: 48px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #05C0FF;
        cursor: pointer;
      }

      .another {
        top: 86vh;
        background: none;
        color: #FF1A00;
      }

      .delay-notice {
        position: absolute;
        font-size: 20px;
        font-weight: bold;
        top: 66vh;
        left: 25%;
        cursor: pointer;
        color: #FF1A00;
      }

    }

    // 移动端样式
    .mobile-home {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      background: url("../../assets/img/home/mobile-bg.jpg") no-repeat center;
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;

      .mobile-home-text {
        margin: 17vh 0 2vh 0;
        width: 95%;
      }

      .home-organize {
        .common-row {
          display: flex;
          margin-top: 4px;
          img {
            margin-right: 5px;
            vertical-align: middle;
            width: 15px;
          }
          .org-name {
            color: #0E45A5;
            font-size: 12px;
            font-weight: 500;
            display: block;
            margin-top: 2px;
          }
          p {
            margin: 0;
          }

          span {
            font-size: 12px;
            font-weight: 500;
            &:first-child {
              color: #222222
            }
          }

        }
      }
      .mobile-sign-up--btn {
        width: 47%;
        margin-top: 4vh;

        &:hover {
          filter: drop-shadow(0 0 2em #2F5CC8);
        }
      }
      .mobile-official-event {
        width: 61%;
        margin-top: 8vh;
      }

      .delay-notice {
        margin-top: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #FF1A00;
        a {
          text-decoration: none;
          color: inherit;
        }
      }

    }

  }

</style>
