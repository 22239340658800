<template>
  <el-dialog custom-class="delay-notice-dialog"
             title="通知"
             :visible="visible" :close-on-click-modal="false"
             :width="isMobilePlatform?'88%':'1000px'"
             @close="visible= false">
    <embed
      :src="pdfUrl"
      type="application/pdf" width="100%" height="550px"></embed>
  </el-dialog>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: '',
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapState(['isMobilePlatform']),
      visible: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        }
      },
      pdfUrl() {
        let baseUrl = process.env.BASE_URL
        return `${baseUrl}file/notice.pdf`
      },
    },
    components: {},
    methods: {

    }
  }
</script>

<style scoped lang="scss">

</style>